<template>
  <div>
    <b-overlay
      :show="$apollo.loading"
      rounded="sm"
    >
      <b-card
        class="mt-2 text-left"
      >
        <!-- search input -->
        <div class="custom-search d-flex justify-content-start">
          <b-form-group
            class="form-inline"
            label="Search"
            label-size="sm"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search applicants"
              type="text"
            />
          </b-form-group>

        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength,
            position: 'top'
          }"
          :rows="filteredRows"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Status -->
            <span
              v-if="props.column.id === 'round' && props.row.programs_applicationformstable"
            ><b-badge
               v-b-tooltip="'Round Number'"
               class="mr-50 mb-25"
               variant="primary"
             >{{ props.row.programs_applicationformstable.round_number }}</b-badge>
              {{ props.formattedRow[props.column.field] }}
            </span>

            <!-- Column: Status-->
            <span v-else-if="props.column.field === 'status'">
              <b-badge
                :variant="statusVariant(props.row.status)"
              >
                {{ props.row.status || 'In-Process' }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <b-button
                :to="{name: 'view-applications', params: {pid: $route.params.id,aid:props.row.id }}"
                size="sm"
                variant="outline-primary"
              >View Applications</b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template
            slot="column-filter"
            slot-scope="props"
          >
            <span v-if="props.column.id === 'round'"><b-form-select
              v-if="props.column.hasOwnProperty('filterOptions') && props.column.filterOptions.customFilter"
              v-model="filter.round"
              :options="[{text: '-- No Filter --', value: null}, ...roundFilterOptions]"
              placeholder="-- No Filter --"
            /></span>
            <span v-if="props.column.field === 'status'"><b-form-select
              v-if="props.column.hasOwnProperty('filterOptions') && props.column.filterOptions.customFilter"
              v-model="filter.status"
              :options="[{text: '-- No Filter --', value: null}, {text: 'Accepted', value: 'accepted'}, {text: 'Declined', value: 'declined'}, {text: 'In-Process', value: 'in-process'}]"
              placeholder="-- No Filter --"
            /></span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-top"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mb-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','20','30']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of <span class="font-medium-4 ml-25"><b-badge
                  variant="light-primary"
                >{{
                  props.total
                }} entries
                </b-badge></span></span>

              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-50"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
    <b-modal
      id="appModal"
      ok-only
      size="lg"
      title="Application Form"
    >
      <form-builder
        v-if="selectedRound !== null"
        v-model="rounds[selectedRound].programs_applicationsquestionstables"
        :inherited-sections="rounds[selectedRound].programs_applicationsquestionstables"
      />
    </b-modal>
  </div>
</template>
<script>
import FormBuilder from '@/views/incubator-portal/programs/components/FormBuilder.vue'
import {
  BBadge,
  BButton,
  BCard,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BPagination,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BButton,
    BFormInput,
    BCard,
    BFormGroup,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BBadge,
    BModal,
    FormBuilder,

  },
  data() {
    return {
      mutationLoading: false,
      selectedRound: null,
      pageLength: 20,
      filter: {
        round: null,
        status: null,
      },
      columns: [
        {
          label: 'Applicant',
          field(row) {
            if (!row.users_organizationtable) return '-'
            return row.users_organizationtable.title
          },
        },
        {
          id: 'round',
          label: 'Shortlisting Round',
          field(row) {
            if (!row.programs_applicationformstable) return ''
            return row.programs_applicationformstable.title
          },
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
          },
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
          },
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      rounds: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        draft: 'light-primary',
        enabled: 'light-success',
        disabled: 'light-secondary',
        null: 'light-primary',
        accepted: 'light-success',
        declined: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filteredRows() {
      if (!this.filter.round && !this.filter.status) return this.rows
      return this.rows.filter(row => row.programs_applicationformstable)
        .filter(row => (this.filter.round === null || row.programs_applicationformstable.round_number === this.filter.round))
        .filter(row => (this.filter.status === null || !row.programs_applicationformstable.status === this.filter.status || (this.filter.status === 'in-process' && !row.programs_applicationformstable.status)))
    },
    roundFilterOptions() {
      return this.rows.filter(e => e.programs_applicationformstable)
        .sort((a, b) => a.programs_applicationformstable.round_number - b.programs_applicationformstable.round_number)
        .map(row => ({
          text: `Round ${row.programs_applicationformstable.round_number} - ${row.programs_applicationformstable.title}`,
          value: row.programs_applicationformstable.round_number,
        }))
        .filter((e, i, a) => a.findIndex(t => t.value === e.value) === i) // Remove duplicates
    },
  },
  methods: {
    addRound() {
      this.rounds.push({
        title: 'New Round',
        round_number: this.rounds.length + 1,
        status: 'draft',
        review_status: 'draft',
        program_id: this.$route.params.id,
        programs_applicationsquestionstables: [],
      })
    },
    updateApplication(i) {
      const payload = this.rounds[i]

      if (payload.programs_applicationsquestionstables.length) {
        payload.programs_applicationsquestionstables = {
          data: payload.programs_applicationsquestionstables,
          on_conflict: {
            constraint: 'programs_applicationsquestionstable_pkey',
            update_columns: ['question', 'input_type', 'options', 'section'],
          },
        }
      } else {
        delete payload.programs_applicationsquestionstables
      }
      this.mutationLoading = true
      this.$apollo.mutate(({
        mutation: gql`mutation($object: programs_applicationformstable_insert_input!) {
            insert_programs_applicationformstable_one(object: $object, on_conflict: {constraint: programs_applicationformstable_pkey, update_columns: title}) {
              id
            }
          }`,
        variables: {
          object: payload,
        },
        update: () => {
          this.$apollo.queries.rounds.refetch()
          this.mutationLoading = false
        },
      }))
    },
    updateApplicationStatus(status, item, col) {
      const update = status === 'enabled' ? 'disabled' : 'enabled'
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_applicationformstable_by_pk(pk_columns: {id: ${item}}, _set: {${col}: ${update}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.rounds.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_applicantstable(where:{program_id: {_eq: ${this.$route.params.id}}}){
            id
            program_id
            status
            current_application_form_id
            users_organizationtable {
              title
            }
            programs_applicationformstable {
              title
              round_number
            }
          }
        }`
      },
      update: data => data.programs_applicantstable,
    },
    rounds: {
      query() {
        return gql`
        {
          programs_applicationformstable(where:{program_id: {_eq: ${this.$route.params.id}}}, order_by: {round_number: asc}){
            id
            round_number
            title
            status
            review_status
            programs_applicationsquestionstables {
              id
              question
              section
              options
              input_type
              is_required
            }
          }
        }`
      },
      update(data) {
        /* eslint-disable no-param-reassign */
        if (data.programs_applicationformstable.length) {
          data.programs_applicationformstable.forEach(e => {
            if (e.status !== 'draft') {
              delete e.programs_applicationsquestionstables
            }
          })
        }
        return data.programs_applicationformstable
      },
    },
  },
}
</script>
